import { UserSkill } from "./skill";

export interface User {
  id: string;

  email: string;
  name: string;

  [key: string]: any;
}
export interface PagedUserResponse {
  totalCount: number,
  items: UserRights[]
}

export interface UserRights {
  id: string;
  userName: string;
  email: string;
  name: string;
  jobTitle: string;
  department: string;
  type: UserType;
  isActive: boolean,
  isAdmin: boolean,
  isSupervisor: boolean,
  hasOutboundRights: boolean,
  hasOperatorRights: boolean,
  licenseType: LicenseType,
  [key: string]: any;
  userSkills?: UserSkill[];
}

export interface UserRightsChanged {
  id: string;
  isActive: boolean,
  isAdmin: boolean,
  isSupervisor: boolean,
  hasOutboundRights: boolean,
  hasOperatorRights: boolean,
  name: string,
  licenseType: LicenseType,
  [key: string]: any;
  userName: string;
}

export enum UserType {
  Database = 0,
  AzureActiveDirectory = 1,
  Office365 = 2
}

export interface AppUser {
  id: string;
  tenantId: string;
  userName: string;
  name: string;
  surname: string;
  email: string;
  emailConfirmed: boolean;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  displayName: string;
  jobTitle: string;
  department: string;
  type: UserType;
  isActive: boolean,
  isAdmin: boolean,
  isSupervisor: boolean,
  hasOutboundRights: boolean
}

export interface AuthorizedUser {
  userData: AppUser;
  tenantName: string;
  token: string;
}

export enum LicenseType {
  ProVoice = 0,
  ProOmni = 1,
  ProOmniPremium = 2
}

export class UserLicense {
  id!: string;
  initialLicense?: LicenseType;
  newLicense?: LicenseType;
}

export interface UserDto {
  id: string;
  name: string;
  userSkills: UserSkill[];
  tenantId?: string;
}

export interface LoginRequest {
  token: string;
  target: string;
  userTenantId: string;
  azureTenantId: string;
}