import { FC, useEffect, useState } from "react";
import { Loader } from "../shared";
import { useNavigate, useParams } from "react-router";
import { useStyles } from "src/theme/styles";
import { Skill, UserSkill } from "src/types/skill";
import { SelectListItem } from "src/types/shared";
import { useTranslation } from "react-i18next";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from 'yup';
import ChangesFormActions from "../shared/formChanges/ChangesFormActions";
import { Grid } from "@material-ui/core";
import { CustomTextField } from "../shared/CustomTextField";
import { HTTPSTATUSCODES, SkillsFieldLimits, SkillsTranslationKeys } from "src/utils/constants";
import { userService } from "src/services/users";
import { skillService } from "src/services/skills";
import { MenuRoutes } from "src/types/routes";
import UtilsHelper from "src/utils/utils-helper";
import { setRowIdForUserSkills } from "src/utils/skillHelper";
import SkillsSection from "./SkillsSection";

const AddEditSkill: FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { skillId } = useParams();
    const [skill, setSkill] = useState<Skill | null>({} as Skill);

    const [assignableUsers, setAssignableUsers] = useState<SelectListItem[]>([] as SelectListItem[]);

    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const getSkill = async () => {
        if (skillId) {
            try {
                const response = await skillService.getSkillById(skillId);

                if (isMountedRef.current && response?.status == HTTPSTATUSCODES.StatusCodeSuccess) {
                    if (response.data.userSkills && response.data.userSkills.length > 0) {
                        setRowIdForUserSkills(response.data.userSkills);
                    }

                    setSkill(response.data);
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        getSkill();
    }, [skillId]);

    const handleCancelForm = (handleReset: (e: any) => void, dirty: boolean) => {
        dirty ? handleReset(null) : navigate(MenuRoutes.Skills);
    };

    const getAssignableUsers = async (): Promise<void> => {
        try {
            const response = await userService.getActiveUsers();

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                setAssignableUsers(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getAssignableUsers();
    }, []);

    const addSkill = async (newSkill: Skill): Promise<void> => {
        setIsSubmitting(true);
        
        try {
            const response = await skillService.createSkill(newSkill);

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                enqueueSnackbar(t('Skills.CreateSucceeded'), { variant: 'success' });
                setIsSubmitting(false);
                navigate(MenuRoutes.Skills);
            }
        } catch (error) {
            if (error?.response?.status === HTTPSTATUSCODES.StatusCodeConflict) {
                enqueueSnackbar(t('Skills.EntityWithSameNameExists'), { variant: 'error' });
            } else {
                enqueueSnackbar(t('Skills.CreateFailed'), { variant: 'error' });
            }

            setIsSubmitting(false);
            console.error(error);
        }
    };

    const updateSkill = async (updatedSkill: Skill): Promise<void> => {
        setIsSubmitting(true);

        try {
            const response = await skillService.updateSkill(updatedSkill);

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                enqueueSnackbar(t('Skills.UpdateSucceeded'), { variant: 'success' });
                setIsSubmitting(false);
                navigate(MenuRoutes.Skills);
            }
        } catch (error) {
            if (error?.response?.status === HTTPSTATUSCODES.StatusCodeConflict) {
                enqueueSnackbar(t('Skills.EntityWithSameNameExists'), { variant: 'error' });
            } else if (error?.response?.status === HTTPSTATUSCODES.StatusCodeForbidden) {
                enqueueSnackbar(t('Skills.EntityDoesNotExist'), { variant: 'error' });
            } else {
                enqueueSnackbar(t('Skills.UpdateFailed'), { variant: 'error' });
            }

            setIsSubmitting(false);
            console.error(error);
        }
    };

    const getAssignedUsers = (userSkills: UserSkill[]) => {
        const skillsWithAssignedUsers = userSkills.filter(x => Boolean(x.userId));

        return skillsWithAssignedUsers.length > 0 ? skillsWithAssignedUsers.map(x => x.userId) : [];
    };

    return (
        <>
            <Loader isOpen={!(isDataLoaded || !skillId)} isInline={false} />
            <Helmet>
                <title>
                    {skillId ? t('Skills.EditSkill') : t('Skills.AddSkill')} | ContactCenter4All
                </title>
            </Helmet>
            {(isDataLoaded || !skillId) &&
                <Formik
                    initialValues={{
                        name: skillId ? skill?.name : "",
                        userSkills: skillId && skill.userSkills ? skill.userSkills : [] as UserSkill[]
                    }}
                    validationSchema={Yup
                        .object()
                        .shape({
                            name: Yup
                                .string()
                                .trim()
                                .matches(new RegExp(UtilsHelper.NameRegExp), t('Shared.SpecialCharacter'))
                                .required(t(SkillsTranslationKeys.Required)),
                            userSkills: Yup
                                .array()
                                .of(Yup.object().shape({
                                    userId: Yup.string().required(t(SkillsTranslationKeys.Required)),
                                    skillLevel: Yup
                                                .number()
                                                .integer(t('Skills.InvalidValue'))
                                                .max(SkillsFieldLimits.SkillLevelMaxValue, `${t('Skills.SkillLevelMaxValueError')} ${SkillsFieldLimits.SkillLevelMaxValue}`)
                                                .min(SkillsFieldLimits.SkillLevelMinValue, `${t('Skills.SkillLevelMinValueError')} ${SkillsFieldLimits.SkillLevelMinValue}`)
                                                .required(t(SkillsTranslationKeys.Required))
                                }))
                        })
                    }
                    onSubmit={(values) => {
                        const editedSkill: Skill = {
                            name: values.name.trim(),
                            userSkills: values.userSkills
                        };

                        if (skillId) {
                            editedSkill.id = skillId;
                        }

                        skillId ? updateSkill(editedSkill) : addSkill(editedSkill);
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, handleReset, touched, values, dirty }): JSX.Element => (
                        <form onSubmit={handleSubmit}>
                            <ChangesFormActions isValid={true} isSubmitting={isSubmitting} handleReset={() => handleCancelForm(handleReset, dirty)} isSaveAction={false} />
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <CustomTextField
                                        size="small"
                                        color="info"
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helperText={touched.name && errors.name}
                                        label={t('Skills.SkillName')}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        variant="outlined"
                                        data-testid="name"
                                    />
                                </Grid>
                                <Grid item xs={5} />
                                <SkillsSection
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    assignableOptions={assignableUsers}
                                    errors={errors}
                                    touched={touched}
                                    assignedOptions={getAssignedUsers(values.userSkills)}
                                    skillId={skillId}
                                    optionsTitle={t('Skills.User')}
                                    optionName="userId"
                                />
                            </Grid>
                        </form>
                    )}
                </Formik>
            }       
        </>
    );
}

export default AddEditSkill;