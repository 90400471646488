import { FC } from 'react';
import { Box, } from '@material-ui/core';
import { CircularProgress } from "@mui/material";

interface LoaderProps {
    isOpen: boolean,
    isInline: boolean
}

const LoaderStyles = {
    inlineLoader: {
        display: "flex",
        justifyContent: "center",
        padding: "20px"
    } as const,
    generalLoader: {
        position: "absolute",
        top: "50%",
        left: "50%"
    } as const
};

const Loader: FC<LoaderProps> = (props) => {

    return (
        props.isOpen &&
        <Box sx={props.isInline ? LoaderStyles.inlineLoader : LoaderStyles.generalLoader}>
            <CircularProgress />
        </Box>

    );
};

export default Loader;
