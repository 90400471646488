import { useEffect } from "react";
import type { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Box, CardMedia, Container, Grid, Typography } from "@material-ui/core";
import { LoginJWT } from "../../components/authentication/login";
import gtm from "../../lib/gtm";

const platformIcons = {
  Amplify: "/static/icons/amplify.svg",
  Auth0: "/static/icons/auth0.svg",
  Firebase: "/static/icons/firebase.svg",
  JWT: "/static/icons/jwt.svg",
};
import { useTranslation } from "react-i18next";

const Login: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Login.Login")} | ContactCenter4All</title>
      </Helmet>
      <Container sx={{ top: "30%", position: "relative" }}>
        <Grid container>
          <Grid item xs={6} sx={{ display: { md: "block", xs: "none" } }}>
            <CardMedia
              image="/static/cc4all_login.jpg"
              sx={{ height: 350, backgroundSize: "100% 90%" }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                p: 4,
                top: "10%",
                position: "relative",
              }}
            >
              <Typography color="textPrimary" gutterBottom variant="body1">
                {t("Login.WelcomeBack")}
              </Typography>
              <Typography color="textPrimary" gutterBottom variant="h5">
                {t("Login.LoginToAccount")}
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <LoginJWT />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Login;
