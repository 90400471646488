import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import { UserDto } from "src/types/user";
import { Loader } from "../shared";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from 'yup';
import { UserSkill } from "src/types/skill";
import { SelectListItem, ChannelTypeEnum } from "src/types/shared";
import { HTTPSTATUSCODES, SkillsFieldLimits, SkillsTranslationKeys } from "src/utils/constants";
import Lockr from 'lockr';
import { StorageKeys } from "src/utils/storageKeys";
import ChangesFormActions from "../shared/formChanges/ChangesFormActions";
import { Grid} from "@material-ui/core";
import { CustomTextField } from "../shared/CustomTextField";
import { MenuRoutes } from "src/types/routes";
import { setRowIdForUserSkills } from "src/utils/skillHelper";
import { skillService } from "src/services/skills";
import { userService } from "src/services/users";
import SkillsSection from "../skills/SkillsSection";

const EditUser: FC = () => {
    const { t } = useTranslation();
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const tenantId = Lockr.get(StorageKeys.TenantId) || '';

    const { userId, queueType, queueId } = useParams();
    const [user, setUser] = useState<UserDto | null>({} as UserDto);

    const [assignableSkills, setAssignableSkills] = useState<SelectListItem[]>([] as SelectListItem[]);

    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const queueTypeRoute = queueType === ChannelTypeEnum.Voice.toString() ? MenuRoutes.Calls : MenuRoutes.Generic;
    const route = queueId ? `${queueTypeRoute}/${MenuRoutes.Queues}/${queueId}` : MenuRoutes.Users;

    const getUser = async () => {
        if (userId) {
            try {
                const response = await userService.getUserById(userId);

                if (isMountedRef.current && response?.status == HTTPSTATUSCODES.StatusCodeSuccess) {
                    if (response.data.userSkills && response.data.userSkills.length > 0) {
                        setRowIdForUserSkills(response.data.userSkills);
                    }

                    setUser(response.data);
                    setIsDataLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        getUser();
    }, [userId]);

    const handleCancelForm = (handleReset: (e: any) => void, dirty: boolean) => {
        dirty ? handleReset(null) : navigate(route);
    };

    const getAllSkills = async (): Promise<void> => {
        try {
            const response = await skillService.getAllSkills();

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                setAssignableSkills(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getAllSkills();
    }, []);

    const getAssignedSkills = (userSkills: UserSkill[]) => {
        const assignedSkills = userSkills.filter(x => Boolean(x.skillId));

        return assignedSkills.length > 0 ? assignedSkills.map(x => x.skillId) : [];
    };

    const updateUser = async (updatedUser: UserDto): Promise<void> => {
        setIsSubmitting(true);

        try {
            const response = await userService.updateUser(updatedUser);

            if (response?.status === HTTPSTATUSCODES.StatusCodeSuccess) {
                enqueueSnackbar(t('Users.UpdateSucceeded'), { variant: 'success' });
                setIsSubmitting(false);
                navigate(route);
            }
        } catch (error) {
            if (error?.response?.status === HTTPSTATUSCODES.StatusCodeForbidden) {
                enqueueSnackbar(t('Users.EntityDoesNotExist'), { variant: 'error' });
            } else {
                enqueueSnackbar(t('Users.UpdateFailed'), { variant: 'error' });
            }

            setIsSubmitting(false);
            console.error(error);
        }
    };
    
    return (
        <>
            <Loader isOpen={!(isDataLoaded || !userId)} isInline={false} />
            <Helmet>
                <title>
                    {t('Users.EditUser')} | ContactCenter4All
                </title>
            </Helmet>
            {(isDataLoaded || !userId) &&
                <Formik
                    initialValues={{
                        name: userId ? user?.name : "",
                        userSkills: userId && user.userSkills ? user.userSkills : [] as UserSkill[]
                    }}
                    validationSchema={Yup
                        .object()
                        .shape({
                            userSkills: Yup
                                .array()
                                .of(Yup.object().shape({
                                    skillId: Yup.string().required(t(SkillsTranslationKeys.Required)),
                                    skillLevel: Yup
                                                .number()
                                                .integer(t('Skills.InvalidValue'))
                                                .max(SkillsFieldLimits.SkillLevelMaxValue, `${t('Skills.SkillLevelMaxValueError')} ${SkillsFieldLimits.SkillLevelMaxValue}`)
                                                .min(SkillsFieldLimits.SkillLevelMinValue, `${t('Skills.SkillLevelMinValueError')} ${SkillsFieldLimits.SkillLevelMinValue}`)
                                                .required(t(SkillsTranslationKeys.Required))
                                }))
                        })
                    }
                    onSubmit={(values) => {
                        const editedUser: UserDto = {
                            id: userId,
                            name: values.name,
                            userSkills: values.userSkills,
                            tenantId: tenantId
                        };
                        
                        updateUser(editedUser);
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, handleReset, touched, values, dirty }): JSX.Element => (
                        <form onSubmit={handleSubmit}>
                            <ChangesFormActions isValid={true} isSubmitting={isSubmitting} handleReset={() => handleCancelForm(handleReset, dirty)} isSaveAction={false} />
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <CustomTextField
                                        size="small"
                                        color="info"
                                        fullWidth
                                        label={t('Users.UserName')}
                                        name="name"
                                        value={values.name}
                                        variant="outlined"
                                        data-testid="name"
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5} />
                                <SkillsSection
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    assignableOptions={assignableSkills}
                                    errors={errors}
                                    touched={touched}
                                    assignedOptions={getAssignedSkills(values.userSkills)}
                                    userId={userId}
                                    optionsTitle={t('Users.Skill')}
                                    optionName="skillId"
                                />
                            </Grid>
                        </form>
                    )}
                </Formik>
            }       
        </>
    );
};

export default EditUser;