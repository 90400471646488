import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";

interface TableSearchProps {
    placeholder: string;
    query: string;
    handleQueryChange: any;
}

const TableSearch: FC<TableSearchProps> = (props) => {
    const { handleQueryChange, query, placeholder } = props;
    const { t } = typeof jest !== 'undefined' ? { t: s => s } : useTranslation();

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                m: -1,
                p: 2,
            }}
        >
            <Box
                sx={{
                    m: 1,
                    maxWidth: "100%",
                    width: 500,
                }}
            >
                <TextField
                    size="small"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        ),
                    }}
                    onChange={handleQueryChange}
                    placeholder={t(placeholder)}
                    value={query}
                    variant="outlined"
                    inputProps={{ "data-testid": "searchBox" }}
                />
            </Box>
        </Box>
    );
};

export default TableSearch;
