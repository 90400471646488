import { useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Button, Collapse, ListItem } from "@material-ui/core";
import type { ListItemProps } from "@material-ui/core";
import customColors from "../utils/customColors";
import { useStyles } from "../theme/styles";

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  sx?: any;
  onClick?: () => void;
}

const NavItem: FC<NavItemProps> = (props) => {
  const classes = useStyles();

  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    sx,
    onClick,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(openProp);

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);

    if (!open) {
      onClick();
    }
  };

  useEffect(() => {
    if (!openProp) {
      setOpen(openProp);
    }
  }, [openProp]);

  let space = 16;

  if (depth > 0) {
    space = 32 + 3 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0,
          ml: 1.5
        }}
        {...other}
      >
        <Button
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: customColors.darkText,
            fontSize: depth === 0 ? "17px" : "15px",
            justifyContent: "flex-start",
            textAlign: "left",
            pl: `${space}px`,
            py: '12px',
            textTransform: 'none',
            width: '220px',
            marginTop: '7px'
          }}
          variant="text"
        >
          <Box className={classes.titleBox}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open && openProp}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <>
      <ListItem
        disableGutters
        sx={{
          display: "flex",
          py: 0,
          mt: -1,
          ml: 1.5,
          ...sx
        }}

      >
        <Button
          onClick={onClick}
          component={path && RouterLink}
          startIcon={icon}
          sx={{
            color: customColors.darkText,
            fontSize: depth === 0 ? "17px" : "15px",
            justifyContent: "flex-start",
            textAlign: "left",
            pl: `${space}px`,
            mr: `${space}px`,
            py: '12px',
            pb: '6px',
            textTransform: 'none',
            width: '220px',
            marginTop: '7px',
            fontWeight: openProp ? '700' : '600',
            ...(
              active && {
                color: customColors.darkText,
                fontWeight: 'fontWeightBold',
                '& img': {
                  filter: 'invert(54%) sepia(27%) saturate(540%) hue-rotate(151deg) brightness(92%) contrast(93%)'
                }
              }
            )
          }}
          variant="text"
          to={path}
        >
          <Box className={classes.titleBox}>{title}</Box>
          {info}
        </Button>
      </ListItem>
    </>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.any
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
