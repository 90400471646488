import { FC } from 'react';
import type { CustomPaginationProps } from '../../src/types/customPagination';
import {
    Box,
    Pagination,
    TablePagination
} from '@material-ui/core';
import { useStyles } from "../theme/styles";
import { useTranslation } from 'react-i18next';
import "src/theme/css/pagination.css";

const CustomPagination: FC<CustomPaginationProps> = (props) => {
    const { nrOfPages, page, totalCount, rowLimit, handlePageChange, onRowsPerPageChange } = props;
    const classes = useStyles();
    const { t } = typeof jest !== 'undefined' ? { t: s => s } : useTranslation();

    return (
        <Box className={classes.paginationContainer}>
            <Pagination
                title="test-pagination"
                count={nrOfPages}
                page={page}
                className={classes.paginationRight}
                showFirstButton
                showLastButton
                disabled={nrOfPages === 1}
                onChange={handlePageChange} />
            <TablePagination
                className={classes.paginationRight}
                labelRowsPerPage={t("Shared.RowsPerPage")}
                component="div"
                count={totalCount}
                page={page - 1}
                onPageChange={handlePageChange}
                rowsPerPage={rowLimit}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </Box>
    );
};

export default CustomPagination;