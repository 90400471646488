import { AssignedTypeEnum } from './shared';

export interface TextEntryPoint {
    id?: string;
    identifier: string;
    name: string;
    email: string;
    queueId?: string; 
    assignedMediaName?: string;
    type?: TypeEnum;
}

export const TextEntryPointAssignedTypeToLabelMapping: Record<AssignedTypeEnum, string> = {
    [AssignedTypeEnum.Queue]: "TextEntryPoint.Queue"
}

export enum TypeEnum {
    Email = 2
}