import { FormControl, Grid, IconButton, ListItemText, MenuItem, Select } from "@material-ui/core";
import { Box } from "@material-ui/system";
import { FC } from "react";
import { useStyles } from "src/theme/styles";
import BlockIcon from '@mui/icons-material/Block';
import { CustomTextField } from "../shared/CustomTextField";
import { SelectListItem } from "src/types/shared";
import { FormHelperText } from "@mui/material";

const UserSkillRow : FC<any> = (props) => {
    const { userSkill, onChange, onBlur, onDelete, touched, errors, index, assignableOptions, assignedOptions, optionName } = props;
    const classes = useStyles();

    const isOptionDisabled = (itemId: string) => {
        return assignedOptions.includes(itemId);
    };

    return (
        <Box className={classes.rowItem} data-testid={`user-skill-${index}`}>
            <Grid item xs={5} className={classes.rowField}>
                <FormControl fullWidth>
                    <Select
                        id={optionName}
                        value={userSkill[optionName]}
                        error={!!touched && touched[optionName] && !!errors && !!errors[optionName]}
                        onChange={onChange}
                        onBlur={onBlur}
                        size="small"
                        name={`userSkills.${index}.${optionName}`} 
                        className={classes.selectComponent}
                        data-testid={`skill-${optionName}-${index}`}
                        key={`skill-${optionName}-${userSkill.rowId}`}
                    >
                        <MenuItem value=''>
                            <ListItemText primary="Select" />
                        </MenuItem>
                        {assignableOptions.map((item: SelectListItem) => (
                            <MenuItem 
                                key={item.id} 
                                value={item.id}
                                disabled={isOptionDisabled(item.id)}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText error>
                        {!!touched && !!touched[optionName] && errors ? errors[optionName] : null}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={5} className={classes.rowField}>
                <CustomTextField
                    size="small"
                    color="info"
                    key={`skill-level-${userSkill.rowId}`}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={userSkill.skillLevel}
                    variant="outlined"
                    name={`userSkills.${index}.skillLevel`}
                    error={!!touched && touched.skillLevel && !!errors && !!errors.skillLevel}
                    helperText={!!touched && !!touched.skillLevel && errors ? errors.skillLevel : null}
                    data-testid={`skill-level-${index}`}
                    type="number"
                />
            </Grid>
            <IconButton
                className={classes.deleteButton}
                onClick={onDelete}
            >
                <BlockIcon className={classes.declineButton} />
            </IconButton>
        </Box>
    );
};

export default UserSkillRow;
