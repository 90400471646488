import { makeStyles } from "@material-ui/styles";
import customColors from '../utils/customColors';

const topPadding = '29px !important';
const justifyContent = 'space-between';

export const useStyles = makeStyles({
    newQueueButton: {
        padding: "24px 24px 16px 24px",
        textAlign: "center",
    },
    mainNavSection: {
        padding: "8px 32px 8px 32px",
    },
    secondaryNavSection: {
        padding: "8px 40px 25px 32px",
        width: "100%",
        '&.MuiBox-root > ul:nth-child(2)': {
            maxHeight: '400px',

            '& ul': {
                maxHeight: '400px'
            },
        }
    },
    titleBox: {
        flexGrow: 1,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    publishButton: {
        backgroundColor: customColors.saveButtonColor,
    },
    saveButton: {
        backgroundColor: customColors.saveButtonColor,
        marginLeft: '16px',
        '&:hover': {
            backgroundColor: customColors.saveButtonHoverColor
        }
    },
    cancelButton: {
        backgroundColor: customColors.cancelButtonColor,
        color: customColors.cancelButtonTextColor,
        marginLeft: '16px',
        '&:hover': {
            backgroundColor: customColors.cancelButtonHoverColor,
            color: customColors.cancelButtonTextHoverColor
        }
    },
    defaultTableHeaderLabel: {
        '& span:nth-child(2)': {
            fontWeight: '500',
            fontSize: '0.875rem'
        },
        marginLeft: '0px',
        marginRight: '0px',
        padding: '0px'
    },
    tableHeaderWithCheckboxAndLabelOnTop: {
        '& span:nth-child(2)': {
            fontWeight: '500',
            fontSize: '0.875rem'
        }
    },
    tableTop: {
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: justifyContent,
        margin: "-8px",
        padding: "16px"
    },
    tableTopRightSide: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row"
    },
    addFormGrid: {
        display: "flex",
        flexDirection: "column"
    },
    formLabel: {
        marginBottom: "10px"
    },
    paginationRight: {
        display: "flex",
        flexDirection: "row-reverse",
        padding: "16px"
    },
    paginationContainer: {
        display: "flex",
        flexDirection: "row-reverse",
    },
    dailyIntervalContainer: {
        display: "flex",
        flexDirection: "row",
    },
    acceptButton: {
        color: "#10dc6b"
    },
    declineButton: {
        color: "#f44336"
    },
    customTextField: {
        "& label": {
            color: "#00bcd4"
        },
        "& fieldset": {
            borderColor: "#00bcd4"
        }
    },
    queueListItem: {
        paddingLeft: "0px"
    },
    selectComponent: {
        '&.Mui-focused': {
            "& fieldset": {
                borderColor: `${customColors.primaryColor} !important`
            }
        }
    },
    gridContainer: {
        marginTop: '16px',
    },
    selectComponentLabel: {
        '&:not(.Mui-focused)': {
            top: '-8px',
        },
        '&.MuiFormLabel-filled': {
            top: '0px',
        }
    },
    rowInput: {
        'padding': '2px 0px',
    },
    tooltipTextArea: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    deleteBtn: {
        '&:hover': {
            'background-color': 'transparent'
        }
    },
    logoImg: {
        'height': '100px !important',
    },
    wrapUpSection: {
        fontWeight: "bold",
        fontSize: '15px',
        display: 'flex!important'
    },
    wrapUpReason: {
        paddingLeft: '36px'
    },
    wrapUpSectionText: {
        paddingLeft: "8px"
    },
    treeView: {
        flexGrow: 1,
        width: 800
    },
    treeViewPaper: {
        display: 'inline-block'
    },
    treeViewItemInput: {
        padding: "8px 8px"
    },
    wrapUpCollectionButton: {
        paddingTop: '18px!important'
    },
    playAnnouncementButton: {
        marginLeft: '20px',
        marginTop: '20px',
        width: '22px',

        "& button": {
            padding: "0px"
        }
    },
    anouncementFileTypeInfo: {
        marginTop: '20px',
        marginBottom: '10px'
    },
    playMediaFileGridItem: {
        display: 'flex',
        justifyContent: 'end'
    },
    mediaFilePlayButton: {
        paddingRight: 0
    },
    redirectTooltip: {
        paddingTop: "21px",
        paddingLeft: "7px"
    },
    redirectAnnouncementTooltip: {
        paddingTop: "20px",
        paddingLeft: "18px"
    },
    redirectDeleteButton: {
        marginLeft: '-2px',
        marginTop: '14px',
        width: '37px',
    },
    rowItem: {
        marginRight: "16px",
        display: "flex",
        marginBottom: "10px"
    },
    rowField: {
        marginRight: "16px"
    },
    settingsKeysAddButton: {
        marginBottom: "4px",
        marginTop: "4px",
        height: "36px"
    },
    settingsAddButton: {
        marginBottom: "10px"
    },
    settingsSectionTitle: {
        marginBottom: "10px"
    },
    deleteButton: {
        '&:hover': {
            'background-color': 'transparent'
        },
        marginBottom: "4px",
        marginTop: "4px",
        height: "36px"
    },
    genericEntryPointRemoveButton: {
        paddingTop: '13px !important'
    },
    genericEntryPointBox: {
        width: '100%'
    },
    genericEntryPointContainer: {
        width: '100%',
        padding: '16px'
    },
    mappingsContainer: {
        paddingBottom: '24px'
    },
    clientContainer: {
        paddingBottom: '16px'
    },
    "@global": {
        "input:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 20px white inset !important"
        }
    },
    formHelperText: {
        color: '#f44336'
    },
    tableHeaderLabel: {
        '& span:nth-child(2)': {
            fontWeight: '500',
            fontSize: '0.875rem'
        }
    },
    desireInput: {
        '& input': {
            width: "27px",
            textAlign: "center"
        }
    },
    usersCancelButton: {
        backgroundColor: customColors.cancelButtonColor,
        color: customColors.cancelButtonTextColor,
        marginLeft: '16px',
        '&:hover': {
            backgroundColor: customColors.cancelButtonHoverColor
        }
    },
    bullseyeRoutingCycleField: {
        width: '50%'
    },
    queueBullseyeFields: {
        maxWidth: '100%',
        width: '161px',
        paddingTop: topPadding,
        display: 'flex'
    },
    queueOptionField: {
        maxWidth: '100%',
        width: '161px',
        paddingTop: topPadding
    },
    queueOptionFlagsContainer: {
        maxWidth: "100%",
        paddingTop: topPadding,
        display: 'flex'
    },
    queueSection: {
        marginTop: '16px',
        marginBottom: '16px'
    },
    queueMembersSearchContainer: {
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: justifyContent,
        margin: '-8px',
        padding: '16px'
    },
    queueMembersSearch: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row"
    },
    usersGridButtons: {
        margin: '16px',
        maxWidth: "100%"
    },
    queueMembersGrid: {
        minWidth: '700px'
    },
    queueDropdown: {
        width: "100%"
    },
    queueDropdownContainer: {
        display: "flex"
    },
    dropdownTooltip: {
        paddingTop: "17px",
        paddingLeft: "5px"
    },
    linkTooltip: {
        paddingLeft: "5px"
    },
    templateLink: {
        paddingTop: "3px"
    },
    queueReasonsButtons: {
        marginRight: "16px",
        marginBottom: "16px"
    },
    queueError: {
        marginTop: "24px"
    },
    routingTimePeriod: {
        paddingTop: topPadding
    },
    removeFromUnavailableAfter: {
        maxWidth: "100%",
        paddingTop: topPadding,
        display: 'flex',
        alignItems: 'start'
    },
    queueEditButtons: {
        marginRight: "16px"
    },
    importButtonContainer: {
        paddingTop: "0px!important",
        display: "flex",
        justifyContent: "flex-end"
    },
    importButton: {
        marginLeft: '16px',
    },
    importErrorMessage: {
        color: customColors.errorRed
    },
    skillSectionTitle: {
        marginBottom: "16px"
    },
    skillAddButton: {
        marginTop: "8px"
    },
    skillError: {
        marginLeft: "14px"
    },
    skillColumn: {
        display: 'flex',
        marginLeft: '10px'
    },
    skillCount: {
        marginRight: '6px'
    },
    usersLicenseType: {
        "&.Mui-focused": {
            "fieldset": {
                "legend": {
                    visibility: "visible",
                    "span": {
                        position: "relative",
                        bottom: "6px"
                    }
                }
            }
        }
    },
    skillsFilter: {
        width: 300,
        paddingBottom: "6px",
        marginRight: "16px"
    },
    usersGridHeader: {
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: justifyContent,
        margin: "-8px",
        padding: "16px"
    },
    usersGrid: {
        minWidth: "700px" 
    },
    agentProperty: {
        paddingLeft: '10px'
    },
    desireRadioGroup: {
        width: '80px'
    },
    textEntryPoint: {
        paddingTop: '20px'
    }
});
