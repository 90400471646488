import { ChangeEvent } from 'react';
import { LabelPlacement, SortOrder, ThemeAlign, ThemeColor, ThemeSize } from '../types/enums';

export interface GetColumnHeaderParam {
    columnName: string;
    columnKey: string;
    columnId: string;
    order: SortOrder;
    orderBy: string;
    handleRequestSort: (property: string) => void;
    testId: string;
    sx: any;
}

export const getColumnHeader = (param: GetColumnHeaderParam) => {
    param.sx ??= { padding: "6px" }
    return {
        key: param.columnKey,
        columnName: param.columnName,
        columnId: param.columnId,
        color: ThemeColor.Primary,
        order: param.order,
        orderBy: param.orderBy,
        onSortChanged: param.handleRequestSort,
        isSortable: true,
        testId: param.testId,
        align: ThemeAlign.Left,
        size: ThemeSize.Small,
        sx: param.sx
    }
}

export interface GetNotSortableColumnHeaderParam {
    columnName: string;
    columnKey: string;
    columnId: string;
    testId: string;
    padding?: string;
    sx?: any;
}

export const getNotSortableColumnHeader = (param: GetNotSortableColumnHeaderParam) => {
    return {
        key: param.columnKey,
        columnName: param.columnName,
        columnId: param.columnId,
        color: ThemeColor.Primary,
        isSortable: false,
        testId: param.testId,
        align: ThemeAlign.Left,
        size: ThemeSize.Small,
        padding: param.padding,
        sx: param.sx,
    }
}

export const getSelectAllColumnHeader = (
    columnKey: string,
    columnId: string,
    selectedAll: boolean,
    selectedSome: boolean,
    handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void,
    testId: string) => {
    return {
        key: columnKey,
        columnName: "",
        columnId: columnId,
        color: ThemeColor.Primary,
        isCheckbox: true,
        isChecked: selectedAll,
        isIndeterminate: selectedSome,
        changeCallback: handleSelectAll,
        testId: testId
    }
}

export interface SelectAllColumnHeaderWithLabelParam {
    columnName: string,
    columnKey: string,
    columnId: string,
    isChecked: boolean,
    handleSelectAll: (event: ChangeEvent<HTMLInputElement>) => void,
    testId: string,
    labelPlacement: LabelPlacement,
    style: any;
}

export const getSelectAllColumnHeaderWithLabel = (param: SelectAllColumnHeaderWithLabelParam) => {
    param.style ??= { width: "2%" };

    return {
        key: param.columnKey,
        columnName: param.columnName,
        columnId: param.columnId,
        color: ThemeColor.Primary,
        isCheckbox: true,
        isChecked: param.isChecked,
        changeCallback: param.handleSelectAll,
        testId: param.testId,
        labelPlacement: param.labelPlacement,
        size: param.labelPlacement === LabelPlacement.Top && ThemeSize.Small,
        style: param.style
    }
}

export interface GetEditColumnHeaderParam {
    columnName: string,
    columnKey: string,
    columnId: string,
    width: string,
}

export const getEditColumnHeader = (param: GetEditColumnHeaderParam) => {
    param.width ??= "3%";

    return {
        key: param.columnKey,
        columnName: param.columnName,
        columnId: param.columnId,
        isEmpty: true,
        testId: param.columnKey,
        sx: { padding: "6px", width: param.width }
    }
}

export interface GetSelectRowElementParam {
    elementKey: string,
    elementId: string,
    isSelected: boolean,
    handleSelectOne: (event: ChangeEvent<HTMLInputElement>, userId: string) => void,
    testId?: string,
    isDisabled: boolean,
    sx: any
}

export const getSelectRowElement = (param: GetSelectRowElementParam) => {
    param.isDisabled ??= false;
    param.sx ??= { width: "2%" }

    return {
        key: param.elementKey,
        id: param.elementId,
        isCheckbox: true,
        isTextElement: false,
        isChecked: param.isSelected,
        changeCallback: param.handleSelectOne,
        checkBoxValue: param.isSelected,
        color: ThemeColor.Primary,
        isDisabled: param.isDisabled,
        sx: param.sx,
        testId: param.testId,
    }
}

export const getTextRowElement = (
    elementKey: string,
    elementId: string,
    content: string,
    sx: any,
    testId?: string) => {
    return {
        key: elementKey,
        id: elementId,
        isCheckbox: false,
        isTextElement: true,
        color: ThemeColor.Primary,
        textContent: content,
        sx: sx,
        size: ThemeSize.Small,
        testId: testId,
        title: content
    }
}

export const getEditRowElement = (
    elementKey: string,
    elementId: string,
    width: string,
    customTemplate: JSX.Element,
    testId?: string) => {
    return {
        key: elementKey,
        id: elementId,
        isCheckbox: false,
        isTextElement: false,
        isCustomTemplate: true,
        color: ThemeColor.Primary,
        customTemplate: customTemplate,
        sx: { width: width, padding: "6px", maxWidth: "150px" },
        size: ThemeSize.Small,
        testId: testId,
    }
}

export const getNrOfTablePages = (totalCount: number, rowsPerPage: number) => {
    const nrOfPages = Math.floor(totalCount / rowsPerPage);
    const number = totalCount % rowsPerPage === 0 ? nrOfPages : nrOfPages + 1;
    return number === 0 ? 1 : number;
}