import jwt_decode from 'jwt-decode';
import { StorageKeys } from './storageKeys';
import { isArray } from 'lodash';
import { Claims } from './constants';

export const getCurrentUserRoles = () => {
    const accessToken = window.localStorage.getItem(StorageKeys.AccessToken);

    if (accessToken == null || accessToken.length === 0) {
        return [];
    }

    const decodedToken = jwt_decode(accessToken);

    if (decodedToken == null) {
        return [];
    }

    var roles = decodedToken[Claims.Role];

    return isArray(roles) ? roles : [roles];
}
