import type { FC } from 'react';
import { Button, } from '@material-ui/core';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Link as RouterLink } from "react-router-dom";

interface CreateButtonProps {
    route: string;
    label: string;
    disabled?: boolean;
}

const CreateButton: FC<CreateButtonProps> = (props) => {
    const { route, label, disabled } = props;

    return (
        <Button
            color="primary"
            startIcon={<AddCircleIcon />}
            variant="contained"
            sx={{ ml: 2 }}
            component={RouterLink}
            to={route}
            disabled={disabled ?? false}
        >
            {label}
        </Button>
    );
};

export default CreateButton;
