import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

export const CustomTextField = withStyles({
    root: {
        "& label": {
            color: "#00bcd4"
        },
        "& fieldset": {
            borderColor: "#00bcd4"
        }
    }
})(TextField);